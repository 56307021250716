module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.lionstory.cz/graphql","debug":{"disableCompatibilityCheck":true,"preview":false,"timeBuildSteps":false,"throwRefetchErrors":false},"schema":{"requestConcurrency":2,"timeout":120000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"previewRequestConcurrency":5},"production":{"hardCacheMediaFiles":false,"allow404Images":false,"allow401Images":false},"type":{"MediaItem":{"localFile":{"maxFileSizeBytes":26214400,"excludeByMimeTypes":["video/mp4"],"requestConcurrency":2},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Reference":{"beforeChangeNode":"./schemaCustomization/Reference.js"},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"custom":[{"name":["Pluto"],"file":"/fonts/pluto.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"087fab1e5987bc247e8c58cc22965b7f"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
